const state = {
  userInfo: '',
  isLogin: false
};
const getters = {

};
const actions = {

};
const mutations = {
  setUserInfo (state, user) {
    state.userInfo = user;
    sessionStorage.setItem('isLogin', true);
    state.isLogin = true;
  },
  removeUserInfo (state) {
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('isLogin');
    state.userInfo = '';
    state.isLogin = false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
