// 判断是否是生产环境
// webpack在开发环境和生产环境分别执行不同的js文件，process.env.NODE_ENV设置了不同的值，process.env.NODE_ENV在生产环境中值为'production'(这个值是在build/build.js中第4行设置的)
var isPro = process.env.NODE_ENV === 'production'
// 如果是生产环境 我们就使用服务器的uri，如果是开发环境，我们就添加/apis前缀
var Url = '';
if (isPro) {
 // Url = 'http://47.107.146.154:40071';
    Url = '/api'
   // Url = 'http://172.18.6.4:40071'
} else {
  Url = 'http://192.168.0.173:40071';
  Url = 'http://192.168.0.164:40081';
  Url = 'http://192.168.0.174:40081';
 // Url = 'http://192.168.0.5:40081';
  //Url = 'http://ytzjadmin.tongchealth.com/api';
 // Url = 'https://ytzj.wmu.edu.cn/api';
  //Url = '/api';

}
export default {
  baseUrl: Url

}
