import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

console.log(Router);
export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/medical'
        },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
            meta: { title: '自述文件' },
            children: [

                {
                    path: 'roles',
                    name: 'Roles',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/roles/RolePage.vue'),
                    meta: { title: '角色管理' }
                },
                {
                    path: 'admin',
                    name: 'Admin',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/roles/AdminPage.vue'),
                    meta: { title: '用户管理' }
                },
                {
                    path: 'menu',
                    name: 'menu',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/roles/PermissionPage.vue'),
                    meta: { title: '菜单管理' }
                },
                {
                    path: 'dict',
                    name: 'dict',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/dict/dict.vue'),
                    meta: { title: '用户管理' }
                },{
                    path: 'publish',
                    name: 'publish',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/publish/publish.vue'),
                    meta: { title: '填报管理' }
                },{
                    path: 'questionnaire',
                    name: 'questionnaire',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/questionnaire/questionnaire.vue'),
                    meta: { title: '问卷管理' }
                },{
                    path: 'medical',
                    name: 'medical',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/medical/medical.vue'),
                    meta: { title: '计划管理' }
                },{
                    path: 'interview',
                    name: 'interview',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/interview/interview.vue'),
                    meta: { title: '面谈管理' }
                },{
                    path: 'academic-manage',
                    name: 'academic-manage',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/academic/academic-manage.vue'),
                    meta: { title: '学术报告管理' }
                },{
                    path: 'group-manage',
                    name: 'group-manage',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/medical/group-manage.vue'),
                    meta: { title: '组会管理' }
                },{
                    path: 'group-check',
                    name: 'group-check',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/medical/group-check.vue'),
                    meta: { title: '组会报告审核' }
                },{
                    path: 'anonymity-manage',
                    name: 'anonymity-manage',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/anonymity/anonymity-manage.vue'),
                    meta: { title: '匿名信箱管理' }
                },{
                    path: 'fill-record',
                    name: 'fill-record',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/medical/fill-record.vue'),
                    meta: { title: '填报情况' }
                },
                {
                    path: 'notice',
                    name: 'Notice',
                    component:  () => import(/* webpackChunkName: "dashboard" */ '../components/page/notice/Notice.vue'),
                    meta: { title: '系统首页' }
                },

                {
                    path: 'interview',
                    name: 'interview',
                    component:  () => import(/* webpackChunkName: "dashboard" */ '../components/page/interview/interview.vue'),
                    meta: { title: '访视期配置' }
                },


                {
                    path: '/dashboard',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/dash/Dashboard.vue'),
                    meta: { title: '系统首页' }
                },
                {
                    path: '/icon',
                    component: () => import(/* webpackChunkName: "icon" */ '../components/page/Icon.vue'),
                    meta: { title: '自定义图标' }
                },
                {
                    path: '/table',
                    component: () => import(/* webpackChunkName: "table" */ '../components/page/BaseTable.vue'),
                    meta: { title: '基础表格' }
                },
                {
                    path: '/tabs',
                    component: () => import(/* webpackChunkName: "tabs" */ '../components/page/Tabs.vue'),
                    meta: { title: 'tab选项卡' }
                },
                {
                    path: '/form',
                    component: () => import(/* webpackChunkName: "form" */ '../components/page/BaseForm.vue'),
                    meta: { title: '基本表单' }
                },
                {
                    // 富文本编辑器组件
                    path: '/editor',
                    component: () => import(/* webpackChunkName: "editor" */ '../components/page/VueEditor.vue'),
                    meta: { title: '富文本编辑器' }
                },
                {
                    // markdown组件
                    path: '/markdown',
                    component: () => import(/* webpackChunkName: "markdown" */ '../components/page/Markdown.vue'),
                    meta: { title: 'markdown编辑器' }
                },
                {
                    // 图片上传组件
                    path: '/upload',
                    component: () => import(/* webpackChunkName: "upload" */ '../components/page/Upload.vue'),
                    meta: { title: '文件上传' }
                },
                {
                    // vue-schart组件
                    path: '/charts',
                    component: () => import(/* webpackChunkName: "chart" */ '../components/page/BaseCharts.vue'),
                    meta: { title: 'schart图表' }
                },
                {
                    // 拖拽列表组件
                    path: '/drag',
                    component: () => import(/* webpackChunkName: "drag" */ '../components/page/DragList.vue'),
                    meta: { title: '拖拽列表' }
                },
                {
                    // 拖拽Dialog组件
                    path: '/dialog',
                    component: () => import(/* webpackChunkName: "dragdialog" */ '../components/page/DragDialog.vue'),
                    meta: { title: '拖拽弹框' }
                },
                {
                    // 国际化组件
                    path: '/i18n',
                    component: () => import(/* webpackChunkName: "i18n" */ '../components/page/I18n.vue'),
                    meta: { title: '国际化' }
                },
                {
                    // 权限页面
                    path: '/permission',
                    component: () => import(/* webpackChunkName: "permission" */ '../components/page/Permission.vue'),
                    meta: { title: '权限测试', permission: true }
                },
                {
                    path: '/404',
                    component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
                    meta: { title: '404' }
                },
                {
                    path: '/403',
                    component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
                    meta: { title: '403' }
                },
                {
                    path: '/donate',
                    component: () => import(/* webpackChunkName: "donate" */ '../components/page/Donate.vue'),
                    meta: { title: '支持作者' }
                }
            ]
        },
        {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ '../components/page/login/Login.vue'),
            meta: { title: '登录' }
        },
        {
            path: '/router-mini',
            component: () => import(/* webpackChunkName: "login" */ '../components/page/routerMini'),
            meta: { title: '跳转小程序' }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});
