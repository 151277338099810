import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
  cameraState: false // 摄像头开启状态
  },
  actions: {

  },
  mutations: {
    setCamera (state, payload) {

      state.cameraState = payload;
      console.log(state.cameraState);
    }
  },
  modules: {
    user
  }
})
