import axios from 'axios'
import apiConfig from './api.config'
import crypto from '../utils/crypto';

let baseUrl = apiConfig.baseUrl;

let instance = axios.create({baseURL: baseUrl, timeout: 30000})
// 添加请求拦截器
instance.interceptors.request.use((config) => {
    // 在发送请求之前
    if (sessionStorage.getItem('userInfo')) {
        // request.headers.common['Authorization'] = "bearer " + store.getters['user/getUserInfo'].token;
        config.headers.common['Authorization'] = JSON.parse(crypto.decrypt(sessionStorage.getItem('userInfo'))).token;
        config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return config
}, (error) => {
    // 请求错误
    // console.log(error)
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(res => {
    // 响应数据
    if (res.status === 200) {
        return res
    }
    return res
}, error => {
    if (error.data && error.data.code === 999) {
        sessionStorage.removeItem('userInfo');
        sessionStorage.removeItem('isLogin');
        location.reload();
    }

    console.log(error)

    // return Promise.reject(error)
},)

let req =  (method, url, data = null, config = {}) => {
    method = method.toLowerCase();
    if (method === 'post') {
       if (data) {
           // data.createuser =  sessionStorage.getItem('username');
       }
        return instance.post(url, data, config)
    } else if (method === 'get') {
        return instance.get(url, {params: data})
    } else if (method === 'delete') {
        return instance.delete(url, {params: data})
    } else if (method === 'put') {
        return instance.put(url, data)
    } else if (method === 'request') {

        var a = document.createElement('a');
        a.href = baseUrl + url;
        a.click();
    } else {
        console.error('未知的method' + method)
        return false
    }
}
export {req, instance, baseUrl};
